// ==========================================================================
// Plyr variables
// https://github.com/selz/plyr
// https://robots.thoughtbot.com/sass-default
// ==========================================================================

// Settings
$plyr-border-box:                       true !default;
$plyr-touch-action:                     true !default;
$plyr-sr-only-important:                true !default;

// Colors
$plyr-color-main: #008133 !default;

// Font sizes
$plyr-font-family:                      Avenir, 'Avenir Next', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif !default;
$plyr-font-size-small:                  14px !default;
$plyr-font-size-base:                   16px !default;

// Captions
$plyr-captions-bg:                      transparentize(#000, .3) !default;
$plyr-captions-color:                   #fff !default;
$plyr-font-size-captions-base:          $plyr-font-size-base !default;
$plyr-font-size-captions-medium:        ceil($plyr-font-size-base * 1.5) !default;
$plyr-font-size-captions-large:         ($plyr-font-size-base * 2) !default;

// Controls
$plyr-control-icon-size:                18px !default;
$plyr-control-spacing:                  10px !default;
$plyr-control-padding:                  ($plyr-control-spacing * .7) !default;
$plyr-video-controls-bg:                #000 !default;
$plyr-video-control-color:              #fff !default;
$plyr-video-control-color-hover:        #fff !default;
$plyr-video-control-bg-hover:           $plyr-color-main !default;
$plyr-audio-controls-bg:                #fff !default;
$plyr-audio-controls-border:            1px solid #dbe3e8 !default;
$plyr-audio-control-color:              #565D64 !default;
$plyr-audio-control-color-hover:        #fff !default;
$plyr-audio-control-bg-hover:           $plyr-color-main;

// Tooltips
$plyr-tooltip-bg:                       transparentize(#000, .3) !default;
$plyr-tooltip-color:                    #fff !default;
$plyr-tooltip-padding:                  ($plyr-control-spacing / 2) !default;
$plyr-tooltip-arrow-size:               4px !default;
$plyr-tooltip-radius:                   3px !default;

// Progress
$plyr-progress-loading-size:            25px !default;
$plyr-progress-loading-bg:              transparentize(#000, .85) !default;
$plyr-video-progress-bg:                transparentize(#fff, .75) !default;
$plyr-video-progress-buffered-bg:       $plyr-video-progress-bg !default;
$plyr-audio-progress-bg:                transparentize(#C6D6DB, .33) !default;
$plyr-audio-progress-buffered-bg:       $plyr-audio-progress-bg !default;

// Range sliders
$plyr-range-track-height:               8px !default;
$plyr-range-thumb-height:               floor($plyr-range-track-height * 2) !default;
$plyr-range-thumb-width:                floor($plyr-range-track-height * 2) !default;
$plyr-range-thumb-bg:                   #fff !default;
$plyr-range-thumb-border:               2px solid transparent !default;
$plyr-range-thumb-shadow:               0 1px 1px transparentize($plyr-video-controls-bg, .85), 0 0 0 1px transparentize(#000, .85) !default;
$plyr-range-thumb-active-border-color:  #fff !default;
$plyr-range-thumb-active-bg:            $plyr-video-control-bg-hover !default;
$plyr-range-thumb-active-scale:         1.25 !default;
$plyr-video-range-track-bg:             $plyr-video-progress-buffered-bg !default;
$plyr-audio-range-track-bg:             $plyr-audio-progress-buffered-bg !default;
$plyr-range-selected-bg:                $plyr-color-main !default;

// Breakpoints
$plyr-bp-screen-sm:                     480px !default;
$plyr-bp-screen-md:                     768px !default;
$plyr-bp-screen-lg:                     1024px !default;