//變數設定
$mq-breakpoints: (
    small:    321px,
	mobile-s: 376px,
    mobile:   480px,
    tablet-s: 700px,
    tablet:   768px,
    desktop:  1024px,
    wide:     1200px
    );

$green:	#539D63;
$brown:	#e56a58;
$lightbrown: #E27254;
$yellowbrown: #9c721a;
$darkyellowbrown: #9c721a;

body{
	background: #fff;
	font-size: 14px;
	color: #222;
	font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, '微軟正黑體', sans-serif;
	//font-weight: 300;
}

header{
	//position: absolute;
	//top: 0px;
	//left: 0px;
	z-index: 10;
	//width: 100%;
}

a, a:hover, a:active, a:focus{
	text-decoration: none;
}

input.form-control, select.form-control, textarea.form-control{
	height: 50px;
	border-radius: 0px;
	border: 1px solid #ddd;
	box-shadow: none;
	&:focus{
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(#28D88F, 0.6);
		border-color: #5ca380;
	}
}
textarea.form-control{
	height: auto;
}

input:-webkit-autofill{
	-webkit-box-shadow: 0 0 0px 1000px #f8f8f8 inset;
}

button{
	outline: none;
	&:hover, &:focus{
		outline: none;
	}
}

.btn-brown{
	background: $lightbrown;
	color: #fff;
	&:hover{
		background: darken($lightbrown, 10%);
		color: #fff;
	}
	&:focus{
		background: darken($lightbrown, 10%);
		color: #fff;
	}
}
.btn-yellowbrown{
	background: $darkyellowbrown;
	color: #fff;
	&:hover{
		background: darken($darkyellowbrown, 10%);
		color: #fff;
	}
	&:focus{
		background: darken($darkyellowbrown, 10%);
		color: #fff;
	}
}
.btn-greenborder{
	background: #fff;
	color: $green;
	border: 1px solid $green;
	border-radius: 20px;
	padding: 5px 25px;
	&:hover{
		background: $green;
		color: #fff;
		border: 1px solid transparent;
	}
	&.btn-xs{
		padding: 2px 10px;
	}
}
.btn-green{
	background: #349e42;
	color: #fff;
	border-radius: 5px;
	padding: 6px 25px;
	border-bottom: 1px solid #00681d;
	i{
		color: #fff;
	}
	&:hover, &:focus{
		background: darken(#349e42, 10%);
		color: #fff;
	}

}
.btn-cancel{
	background: #c1433f;
	color: #fff;
	border-radius: 20px;
	i{
		color: #fff;
	}
	&:hover, &:focus{
		background: darken(#c1433f, 10%);
		color: #fff;
	}
}
.btn-gray{
	background: #f8f8f8;
	color: #222;
	border: 1px solid #ddd;
}
.btn-radius{
	border-radius: 20px;
}
.btn-checkout-type{
	border-radius: 0px;
	padding: 10px 0;
}
.btn-thin{
	padding: 5px 80px;
	letter-spacing: 1px;
	border-radius: 20px;
}
//
.mainnav-wrap{
	background: #fff;
	height: 85px;
	//border-bottom: 1px solid rgba(152, 142, 121, 0.58);
	.logo{
		margin-top: 17px;
		width: 120px;
		height: auto;
		margin-left: 5px;
	}
	.main-nav{
		text-align: left;
		margin: 0;
		padding: 0;
		height: 85px;
		>li{
			list-style: none;
			display: block;
			float: left;
			text-align: center;
			width: 120px;
			height: 85px;
			padding: 35px 0 35px 0;
			vertical-align: top;
			position: relative;
			&:after{
				content: '|';
				color: #999;
				font-weight: 100;
				display: inline-block;
				position: absolute;
				right: 0;
				top: 35px;
			}
			&:last-child:after{
				display: none;
			}
			.dropdown-menu{
				top: 70px;
				border-radius: 0px;
				/*border: 1px solid #82ab7f;*/
				border: none;
				min-width: 100%;
			}
			a{
				&:hover, &:focus{
					text-decoration: none;
				}
			}
			.title{
				display: block;
				&.cht-jp{
					color: #222;
					font-size: 15px;
					letter-spacing: 1px;
				}
				&.eng{
					color: #65a542;
					text-shadow: 1px 1px 0px #fff;
					font-weight: bold;
				}
			}
			&:hover, &.active{
				//border-bottom: 3px solid $green;
				.title.cht-jp{
					color: #00A850;
				}
			}
			&.button-nav{
				width: auto;
				padding-left: 20px;
				i{
					font-size: 22px;
					color: #333;
					display: block;
					width: 40px;
					height: 40px;
					border-radius: 40px;
					text-align: center;
					border: 1px solid #D2DBD1;
					line-height: 40px;
				}
				&:hover{
					border: none;
					i{
						border: 1px solid $green;
						color: $green;
					}
				}
			}
			a:hover{
				text-decoration: none;
			}
			@include mq(desktop, wide) {
				width: 110px;
			}
			@include mq(tablet, desktop) {
				width: 80px;
			}
		}
	}
}
.affix{
	background: #fff;
	width: 100%;
	.mainnav-wrap{
		height: 40px;
		.logo{
			margin-top: 5px;
			width: 70px;
			height: auto;
		}
		.main-nav{
			text-align: right;
			margin: 0;
			padding: 0;
			li{
				list-style: none;
				display: inline-block;
				text-align: center;
				width: 120px;
				height: 40px;
				padding: 10px 0;
				vertical-align: top;
				.title{
					&.cht-jp{
						color: #333;
						font-size: 14px;
						font-weight: normal;
					}
					&.eng{
						display: none;
					}
				}
				&.button-nav{
					width: auto;
					padding-left: 20px;
					padding-top: 7px;
					i{
						font-size: 16px;
						color: #222;
						display: block;
						width: 26px;
						height: 26px;
						border-radius: 26px;
						text-align: center;
						border: 1px solid #D2DBD1;
						line-height: 26px;
					}
				}
			}
		}
	}
}

//second third menu
.second-menu, .third-menu{
	//height: 40px;
	text-align: right;
	a{
		color: #333;
		font-size: 12px;
		padding: 2px 10px;
		position: relative;
		&:after{
			content: '|';
			font-size: 12px;
			color: #aaa;
			position: absolute;
			top: 0px;
			right: -2px;
		}
		&:hover{
			text-decoration: none;
		}
		&:last-child:after{
			content: '';
		}
		&.no-endline:after{
			content: '';
		}
		i{
			margin-right: 4px;
		}
	}
	input{
	    padding: 4px 10px;
	    height: 26px;
	    font-size: 12px;
	    color: #222;
	    outline: none;
		border: 1px solid #ccc;
		width: 130px;
		&::-webkit-input-placeholder{
			color: #999;
		}
		&:focus{
			border: 1px solid #555;
		}
	}
	.search-input{
		position: relative;
		i.gosearch{
			position: absolute;
			right: 6px;
			top: 1px;
			color: #666;
		}
	}
}
.second-menu{
	margin-top: 15px;
	padding: 2px 0;
	form{
		display: inline-block;
	}
}
.third-menu{
	position: relative;
	padding: 3px 0;
	.btn{
		color: #fff;
	}
	.dropdown{
		display: inline-block;
	}
}
.cart-dialog{
	//display: none;
	background: #fff;
	position: absolute;
	z-index: 15;
	top: 40px;
	right: 0px;
	width: 400px;
	border: 1px solid #ddd;
	padding: 15px;
	letter-spacing: 1px;
	.cart-dialog-list{
		padding: 0px;
		margin: 0px;
		width: 100%;
		td{
			vertical-align: middle;
			text-align: left;
		}
		tbody td{
			border-bottom: 1px solid #eee;
			padding-top: 8px;
			padding-bottom: 8px;
		}
		.cdl-img{
			display: block;
			width: 40px;
			height: 40px;
			background-size: cover;
			background-position: center;
		}
		.cdl-title{
			font-size: 13px;
			color: #333;
			padding: 0 10px;
		}
		.cdl-number{
			font-size: 15px;
			color: #666;
			padding: 0 5px;
		}
		.cdl-price{
			font-size: 13px;
			color: #98580C;
			padding: 0 5px;
		}
		.cdl-delete{
			padding: 0 5px;
			text-align: right;
			i{
				color: #000;
				font-size: 16px;
			}
		}
		tfoot{
			td{
				text-align: right;
				padding-top: 10px;
				font-size: 13px;
			}
			.btn{
				margin-left: 15px;
			}
			.cdl-total-price{
				color: #98580C;
				margin: 0 3px;
			}
		}
	}
}

body{
	padding-bottom: 40px;
}
//Footer
footer{
	//height: 110px;
	.footer-links-bg{
		background: #f3f3f3;
		border-top: 1px solid #eeeeee;
		padding-top: 20px;
		margin-top: 80px;
	}
	.footer-links{
		margin-bottom: 25px;
		.footer-links-div{
			text-align: center;
			padding: 0 20px;
			h4{
				font-size: 16px;
				font-family: Arial, 'xingothic-tc';
				color: #444;
				border-bottom: 2px dotted #ddd;
				margin-bottom: 8px;
				padding-bottom: 4px;
			}
			p{
				font-size: 13px;
				a{
					color: #222;
					&:hover{
						text-decoration: none;
						color: #aaa;
					}
				}
			}
		}
	}
	.footer-content-bg{
		position: fixed;
		bottom: -2px;
		left: 0px;
		width: 100%;
		background: #333;
		height: 40px;
		z-index: 9999;
	}
	.footer-content{
		font-size: 12px;
		line-height: 20px;
		padding-top: 10px;
		letter-spacing: 0.5px;
		letter-spacing: 1px;
		color: #fff;
	}
	.footer-nav{
		text-align: right;
		margin: 0;
		padding: 0;
		padding-top: 45px;
		li{
			list-style: none;
			display: inline-block;
			text-align: center;
			padding-right: 15px;
			&:after{
				content: '|';
				color: #333;
				padding-left: 15px;
			}
			&:last-child:after{
				display: none;
			}
			a{
				color: #333;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}

//MainContent
.main-content{
	min-height: 600px;
	padding-top: 30px;
	//margin-bottom: 50px;
}
.index-wrap{
	margin-top: -30px;
}

//IndexSlider
.index-slider{
	//border-top: 1px solid #e8c271;
	.bx-wrapper{
		box-shadow: none;
		border: none;
		margin-bottom: 0px;
	}
	.bx-wrapper .bx-next{
		background: url("/img/arrow-right.png") no-repeat;
	}
	.bx-wrapper .bx-prev{
		background: url("/img/arrow-left.png") no-repeat;
	}
	li{
		padding-top: 150px;
		height: 700px;
		background-size: cover;
		background-position: center;
		width: 100%;
		display: block;
	}
	.bx-pager{
		position: absolute;
		bottom: 20px;
		width: 100%;
		text-align: center;
		&.bx-default-pager a{
			background: #0dae00;
			border: 1px solid #0dae00;
			&.active{
				background: #fff;
				border: 1px solid #0dae00;
			}
		}
	}
}

//IndexProduct
.leafline{
	margin-bottom: 10px;
	width: 270px;
	height: auto;
}

.index-product-intro{
	&.brown{
		h3{
			color: $brown;
			border-bottom: 1px solid rgba($brown,0.5);
			&:after{
				content: '';
				display: block;
				width: 80px;
				height: 2px;
				background: $brown;
				position: absolute;
				bottom: 0px;
				left: 0px;
			}
		}
	}
	&.green{
		h3{
			color: #65a542;
			border-bottom: 1px solid rgba(#65a542,0.5);
			&:after{
				content: '';
				display: block;
				width: 80px;
				height: 2px;
				background: #65a542;
				position: absolute;
				bottom: 0px;
				left: 0px;
			}
		}
	}
	&.yellowbrown{
		h3{
			color: $yellowbrown;
			border-bottom: 1px solid rgba($yellowbrown,0.5);
			&:after{
				content: '';
				display: block;
				width: 80px;
				height: 2px;
				background: $yellowbrown;
				position: absolute;
				bottom: 0px;
				left: 0px;
			}
		}
	}
	>.container{
		padding: 85px 0;
	}
	.ipi-content{
		text-align: center;
		h3{
			font-size: 26px;
			line-height: 40px;
			padding-bottom: 20px;
			letter-spacing: 2px;
			margin: 10px 30px 60px 30px;
			font-family: Arial, 'xingothic-tc';
			font-weight: 300;
			position: relative;
		}
		p{
			text-align: left;
			font-size: 16px;
			color: #333;
			letter-spacing: 1px;
			padding: 0 70px;
			margin-bottom: 20px;
			line-height: 32px;
			text-align: justify;
		}
		.btn{
			margin-top: 25px;
		}
	}
	.hyc-inside{
		width: 390px;
		height: auto;
		margin: 15px 0 25px 0;
	}
	.hyc-intro-img{
		margin-top: 20px;
	}
	.rock-intro-img{
		margin-top: 15px;
	}
	.ipi-img-bg{
		background-position: center;
		background-size: cover;
		height: 390px;
	}
}

.main-title{
	text-align: center;
	span{
		display: inline-block;
		&.big{
			color: #0e411a;
			font-size: 30px;
			padding-bottom: 4px;
			border-bottom: 1px solid #b3e6c0;
			margin-bottom: 3px;
			letter-spacing: 5px;
			padding: 4px 200px 10px 200px;
			position: relative;
			&:after{
				content: '';
				display: block;
				width: 80px;
				height: 2px;
				background: #0dae00;
				position: absolute;
				bottom: 0px;
				left: 0px;
			}
		}
		&.small{
			display: block;
			font-family: "Times New Roman","微軟正黑體";
			font-style: italic;
			color: #333;
			letter-spacing: 2px;
			font-size: 16px;
		}
	}
}

//IndexShop
.index-shop{
	padding: 60px 0;
	.index-products{
		margin-top: 80px;
		.col-sm-3{
			padding: 0 10px;
		}
	}
	.index-product-item{
		padding: 0 3px;
		text-align: center;
		a:hover{
			text-decoration: none;
		}
		.ipi-img{
			position: relative;
			width: 100%;
			height: 280px;
			background-size: cover;
			background-position: center;
			margin-bottom: 25px;
			&:before{
				content: '';
				width: 30px;
				height: 3px;
				background: #0E411A;
				display: block;
				top: 0px;
				left: 0px;
			}
		}
		.ipi-title{
			font-size: 18px;
			color: #16984d;
			letter-spacing: 1px;
			margin: 17px 0;
		}
		.ipi-info{
			color: #222;
			line-height: 22px;
			margin-bottom: 20px;
			letter-spacing: 1px;
			height: 22px;
			overflow-y: hidden;
		}
	}
}

//IndexNew
.index-new{
	padding: 80px 0;
	background: #f8f8f8;
	.index-news{
		margin-top: 50px;
	}
	.index-new-item{
		padding: 0 15px;
		text-align: left;
		a:hover{
			text-decoration: none;
		}
		.ini-time{
			font-size: 12px;
			color: #0E411A;
			text-align: right;
			margin-bottom: 1px;
		}
		.ini-img{
			position: relative;
			width: 100%;
			height: 235px;
			background-size: cover;
			background-position: center;
			margin-bottom: 10px;
			&:before{
				content: '';
				width: 30px;
				height: 3px;
				background: #0E411A;
				display: block;
				top: 0px;
				left: 0px;
			}
		}
		.ini-info{
			color: #222;
			line-height: 22px;
			margin-bottom: 13px;
			letter-spacing: 1px;
		}
	}
}

//IndexAbout
.index-about{
	position: relative;
	.index-about-img{
		background-size: cover;
		background-position: center;
		height: 530px;
		width: 50%;
		float: left;
	}
	.index-about-text{
		background: #51ce90;
		width: 50%;
		float: left;
		height: 530px;
		padding: 130px 120px;
		.ibt-title{
			font-family: Arial, 'xingothic-tc';
			color: #fff;
			font-size: 26px;
			line-height: 36px;
		}
		.ibt-text{
			color: #fff;
			font-size: 16px;
			letter-spacing: 1px;
			line-height: 32px;
			text-align: justify;
		}
	}
}

//Sidebar
.sidebar{
	margin-top: 20px;
	.sb-div{
		background: #F7FFF6;
		border: 1px solid #E3E4E2;
		margin-bottom: 35px;
		padding: 15px 30px;
		&.sb-div-noborder{
			border: none;
		}
	}
	.sb-title{
		font-size: 14px;
		margin: 0;
		color: #539D63;
		position: relative;
		&:after{
			content: '';
			width: 66px;
			height: 4px;
			background: #0E411A;
			position: absolute;
			right: 0px;
			bottom: 0px;
		}
	}
	.sb-cate-title{
		font-size: 15px;
		font-weight: bold;
		padding-left: 6px;
		a{
			color: #5a4013;
		}
		&:before{
			content: '';
			width: 3px;
			height: 14px;
			display: inline-block;
			background: #5a4013;
			position: relative;
		    top: 2px;
		    left: -5px;
		}
	}
	.sb-cate-productlist{
		margin: 0 0 20px 0;
		border-bottom: 1px dotted #777;
		padding: 0 0 10px 0;
		li{
			margin-bottom: 9px;
			list-style: none;
			text-indent: -12px;
			padding-left: 12px;
			a{
				color: #444;
				&:before{
					content: '>';
					color: #8AAC9A;
					margin-right: 4px;
				}
			}
		}
	}
	form{
		label{
			margin-bottom: 0px;
			color: #539D63;
			width: 100%;
			.forget-pw{
				color: #aaa;
				font-size: 12px;
				&:hover{
					text-decoration: none;
				}
			}
		}
		.form-control{
			margin-bottom: 15px;
			border: 1px solid #C8E1C8;
		}
	}
	.sb-login{
		.col-sm-6{
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.sb-catelog-ul{
		margin: 0px;
		padding: 0px;
		.sb-catelog-item{
			list-style: none;
			margin-bottom: 10px;
			width: 100%;
			clear: both;
			height: 50px;
			.sci-img{
				width: 50px;
				height: 50px;
				background-size: cover;
				background-position: center;
				display: block;
				float: left;
				margin-right: 10px;
				border-radius: 50px;
			}
			.sci-title{
				float: left;
				line-height: 50px;
				font-size: 16px;
				color: #222222;
			}
		}
	}
	.sb-links-ul{
		margin: 15px 0 0 0;
		padding: 0;
		li{
			list-style: none;
			margin: 8px 0;
		}
		a{
			color: #4F725C;
			&:hover{
				text-decoration: none;
			}
			&:before{
				content: '>';
				color: #8AAC9A;
				margin-right: 4px;
			}
		}
	}
}

.page-title{
	color: #4b5854;
	font-size: 36px;
	line-height: 40px;
	margin: 0 0 15px 0;
	font-family: Times, '微軟正黑體';
	b{
		font-weight: normal;
		color: #006a2f;
	}
	span{
		font-size: 12px;
		letter-spacing: 0px;
		margin-left: 5px;
		color: #666;
		position: relative;
		//border-bottom: 1px solid #c8c8c8;
		top: -18px;
	}
}

.article-page{
	padding-top: 20px;
	.ap-block{
		margin-bottom: 80px;
	}
	.ap-paragraph{
		margin-bottom: 50px;
	}
	.ap-step-text{
		font-size: 13px;
		color: #666;
		line-height: 28px;
	}
	.ap-video-title{
		margin: 0 0 15px 0;
		font-weight: normal;
	}
	.ap-videos{
		.col-sm-6{
			padding-left: 20px;
			padding-right: 20px;
		}
		.ap-video-item{
			margin-bottom: 65px;
		}
	}
	.quote{
		margin: 40px 0;
		border: 1px solid #afa593;
		padding: 30px;
		width: 100%;
		h4{
			color: #9c721a;
		}
	}
	h3{
		color: #9c721a;
		font-size: 26px;
		border-bottom: 1px solid #afa593;
		padding-bottom: 24px;
		margin-bottom: 40px;
		margin-top: 70px;
		&:first-child{
			margin-top: 10px;
		}
		&.green{
			color: #65a542;
		}
	}
	h4{
		font-size: 18px;
		margin-bottom: 33px;
		color: #000000;
		-webkit-text-shadow: 0 0 0.3px #4e4e4e;
		-moz-text-shadow: 0 0 0.3px #4e4e4e;
		text-shadow: 0 0 0.3px #4e4e4e;
		margin: 26px 0 26px 0;
		letter-spacing: 0.01em;
		border-left: 3px solid #AE1D00;
		padding-left: 15px;
		&.margintop0{
			margin-top: 0px;
		}
		&.highlight{
			color: #dc9a00;
			letter-spacing: 1px;
			font-weight: bold;
			border-left: none;
			padding-left: 0px;
		}
		&.highlight2{
			color: #886cad;
			letter-spacing: 1px;
			font-weight: bold;
			border-left: none;
			padding-left: 0px;
		}
	}
	h5{
		font-size: 16px;
		font-weight: bold;
		line-height: 30px;
		margin-bottom: 5px;
	}
	p{
		font-size: 15px;
		line-height: 32px;
		//margin-bottom: 16px;
		color: #444;
		font-weight: normal;
		margin: 20px 0;
		b{
			color: #77471e;
			font-weight: bold;
			margin: 0 3px;
			&.red-bullet{
				color: #c93d06;
				font-size: 18px;
			}
			&.blue-bullet{
				color: #00b2c9;
				font-size: 18px;
			}
		}
	}
	ol, ul{
		padding: 0;
		margin: 0;
		li{
			margin-left: 20px;
			font-size: 16px;
			line-height:34px;
			margin-bottom: 5px;
			color: #333;
		}
	}
	img{
		margin: 20px 0;
	}
	.ni-show-img{
		width: 100%;
	}
	&.smallword{
		p{
			font-size: 14px;
			line-height:26px;
		}
		ol,ul{
			li{
				font-size: 14px;
				line-height:26px;
			}
		}
	}
	.embed-element{
		margin: 40px 0;
		border: 1px solid #afa593;
		padding: 30px;
		background: #f8f8f8;
		.embed-responsive{
			border: 1px solid #afa593;
		}
	}
}
.promo-div{
	background: #F1F6F6;
	padding: 20px 30px;
	//border-radius: 40px;
	margin: 30px 0;
	.promo-img{
		width: 100px;
		height: 100px;
		border-radius: 100px;
		float: left;
		margin: 0 20px 0 0;
	}
	.promo-text{
		line-height: 28px;
		font-size: 16px;
		//font-weight: bold;
		padding: 25px 0;
		letter-spacing: 1px;
		color: #3d3c3f;
		margin: 0;
	}
}

.news-item{
	border-bottom: 1px dotted #ccc;
	padding: 15px 0;
	.ni-img{
		width: 100%;
		height: 200px;
		background-size: cover;
		background-position: center;
		display: block;
	}
	a{
		&:hover{
			text-decoration: none;
		}
	}
	.ni-time{
		color: #0E411A;
		font-size: 14px;
	}
	.ni-title{
		font-size: 18px;
		font-weight: bold;
		color: #222;
		margin: 5px 0 10px 0;
	}
	.ni-show-title{
		color: #9c721a;
		font-size: 26px;
		border-bottom: 1px solid #afa593;
		padding-bottom: 24px;
		margin-bottom: 60px;
	}
	.ni-content{
		font-size: 15px;
		line-height: 32px;
		color: #444;
		font-weight: normal;
		margin: 20px 0;
	}
	a{
		color: #333;
	}
}

.cate-img{
	margin-bottom: 20px;
}

.product-list{
	.product-item{
		position: relative;
		margin-bottom: 60px;
		height: 401px;
		a{
			&:hover{
				text-decoration: none;
			}
		}
		.pi-img{
			background-size: cover;
			background-position: center;
			width: 100%;
			height: 250px;
		}
		.pi-title{
			color: #0D8229;
			font-size: 15px;
			line-height: 23px;
			margin: 13px 0 10px 0;
			letter-spacing: 1px;
		}
		.pi-info{
			color: #222;
			font-size: 13px;
			line-height: 20px;
			letter-spacing: 1px;
		}
		.pi-price{
			color: #E83C19;
			display: inline-block;
			width: 100px;
			font-size: 15px;
		}
		.pi-addcart{
			position: absolute;
			bottom: -4px;
			right: 0;
		}
	}
	.itemPID{
		margin-top: 10px;
	}
}

.product-detail{
	margin-bottom: 25px;
	.pd-imgs{
		.pd-img-big{
			width: 100%;
			background-size: cover;
			background-position: center;
			height: 350px;
			margin-bottom: 10px;
		}
		.pd-img-small{
			margin: 0 -5px;
			padding: 0;
			li{
				width: 25%;
				float: left;
				list-style: none;
				padding: 0 5px;
			}
			.pd-img-small-item{
				width: 100%;
				height: 70px;
				background-size: cover;
				background-position: center;
			}
		}
	}
	.pd-sku{
		margin: 30px 0;
		input.form-control, select.form-control{
			width: 48%;
			margin-right: 1%;
			float: left;
			margin-bottom: 10px;
			height: 36px;
			vertical-align: top;
		}
		input#quantity{
			width: 50px;
			text-align: center;
		}
		.pd-number input{
			height: 36px;
			width: 40px;
			margin: 0;
			color: #555;
			text-align: center;
		}
		.pd-number{
			display: inline-block;
			margin: 0px 4px;
			height: 30px;
			vertical-align: middle;
		}
		.pd-subr, .pd-add{
			vertical-align: top;
			width: 30px;
			height: 36px;
			line-height: 36px;
			display: inline-block;
			text-align: center;
			border: 1px solid #e4e4e4;
			font-size: 12px;
			color: #aaa;
			background: #fff;
			cursor: pointer;
			&:hover{
				background: #e8e8e8;
			}
		}
	}
	.pd-intro{
		margin-bottom: 30px;
		.pd-title{
			color: #222;
			font-size: 26px;
			border-bottom: 1px solid #afa593;
			padding-bottom: 24px;
			margin-bottom: 25px;
			margin-top: 0px;
			font-family: Arial, '微軟正黑體';
			font-weight: bold;
			span{
				color: #888;
				font-size: 13px;
				font-weight:normal;
				line-height: 30px;
				margin-right: 5px;
				position: relative;
				top: -2px;
			}
		}
		.pd-short-info{
			color: #666;
			font-size: 15px;
			line-height: 25px;
			letter-spacing: 1px;
			margin-bottom: 25px;
		}
		.pd-price{
			color: #E83C19;
			width: 100px;
			font-size: 20px;
		}
		.pi-addcart{
			margin-top: 30px;
			width: 230px;
			i{
				color: #fff;
				margin-right: 10px;
			}
		}
	}
}
.product-information{
	.pd-info{
		margin: 30px 0 50px 0;
		h3{
			color: #008b13;
			font-size: 18px;
			line-height: 36px;
			margin: 0 0 10px 0;
			//background: #fffaf0;
			padding: 3px 0;
			font-weight: bold;
		}
		h5{
			font-size: 15px;
			font-weight: normal;
			line-height: 32px;
			margin: 0 0 15px 0;
			color: #222;
			//background: #fffaf0;
			color: #ff4500;
			padding: 3px 0;
		}
		h3+p{
			margin: 0px 0px 25px 0px;
		}
		h4{
			font-size: 18px;
			color: #9c721a;
			margin-bottom: 33px;
			margin: 26px 0 26px 0;
			letter-spacing: 0.01em;
			margin: 0 0 15px 0;
		}
		hr{
			border-top: 1px solid transparent;
			margin: 20px 0;
		}
		p{
			font-size: 15px;
			line-height: 32px;
			color: #444;
			font-weight: normal;
			margin: 10px 0;
			em{
				color: #ff4500;
				font-style: normal;
			}
			u{
				font-family: Sans-Serif;
				text-decoration: none;
				display: inline-block;
				width: 16px;
				text-align: center;
				margin: 0 3px;
				font-weight: bold;
			}
		}
		ul,ol{
			li{
				font-size: 15px;
				line-height: 32px;
				margin-bottom: 1px;
				color: #444;
			}
		}
		table.table{
			border-bottom: 1px solid #ddd;
			td.highlight{
				background: #f8f8f8;
				color: #000;
				font-weight: bold;
			}
		}
	}
	.pd-note{
		position: relative;
		background: #F1F6F6;
		padding: 25px;
		h3{
			position: absolute;
			margin: 0;
			top: -5px;
			left: 0;
			font-size: 18px;
			color: #0E411A;
		}
		p{
			font-size: 14px;
			line-height: 30px;
		}
	}
}
.contact-desc{
	font-size: 15px;
	color: #222;
	line-height: 32px;
	letter-spacing: 1px;
	margin-bottom: 50px;
	margin-top: 60px;
	//font-family: 'xingothic-tc';
	//text-align: justify;
}
.contact-button{
	margin: 30px 0;
}
.contact-banner{
	margin-bottom: 15px;
}
.contact-form{
	input, select, textarea{
		border: 1px solid #c6b288;
	}
	.form-group{
		margin-bottom: 30px;
		label{
			font-weight: normal;
			font-size: 16px;
			color: #3d3d3d;
		}
	}
}

.login-form{
	border: 2px solid #ddd;
	background: #fff;
	//box-shadow: 0px 5px 12px #ddd;
	padding: 30px 30px;
	margin-bottom: 80px;
	input, select, textarea{
		border: 1px solid #c6b288;
	}
	.form-group{
		margin-bottom: 30px;
		label{
			font-weight: normal;
			font-size: 16px;
			color: #3d3d3d;
		}
	}
	.login-button{
		margin-top: 30px;
		text-align: center;
		button, a{
			margin: 0 5px;
			padding: 6px 25px;
		}
	}
}

.checkout-step{
	margin: 40px 0 60px 0;
	.step{
		float: left;
		text-align: center;
		width: 25%;
		height: 20px;
		.step-text{
			font-size: 13px;
			display: block;
			position: relative;
			color: #999;
			margin-bottom:3px;
			&:after{
				content: '';
				width: 100%;
				position: absolute;
				top: 33px;
				left: 0px;
				height: 3px;
				background: #ccc;
			}
		}
		.step-no{
			display: block;
			font-size: 14px;
			color: #fff;
			width: 28px;
			height: 28px;
			border-radius: 28px;
			line-height: 28px;
			background: #ccc;
			margin: 0 auto;
			position: relative;
		}
		&.active{
			.step-text{
				color: #539D63;
			}
			.step-no{
				background: #539D63;
				box-shadow: 0px 0px 2px #37C84F;
				border: 1px solid #C8E9CD;
			}
		}
	}
}

.checkout-cart{
	padding: 30px 40px;
	background: #FFFFFF;
	border: 1px solid #DDDDDD;
	box-shadow: 0px 0px 8px 0px rgba(183,183,183,0.50);
}
.checkout-title{
	font-size: 16px;
	color: #A2A2A2;
	letter-spacing: 1px;
}
.cart-table{
	.cart-thead{
		margin-bottom: 15px;
		border-bottom: 1px solid #e8e8e8;
		.ct-list{
			text-align: center;
			display: inline-block;
			font-size: 14px;
			color: #A2A2A2;
			float: left;
			height: 30px;
		}
		.ct-list:nth-child(1){ width: 50%; text-align: left;}
		.ct-list:nth-child(2){ width: 23%;}
		.ct-list:nth-child(3){ width: 10%;}
		.ct-list:nth-child(4){ width: 10%;}
		.ct-list.ct-list-step3-last{ width: 17%;}
	}
	.cart-tbody{
		margin-bottom: 40px;
		.cart-product{
			margin: 10px 0 10px 0;
			height: 70px;
			padding: 0 0 20px 0;
			vertical-align: middle;
			border-bottom: 1px dotted #ddd;
			.cp-list{
				display: inline-block;
				text-align: center;
				font-size: 14px;
				vertical-align: middle;
				float: left;
			}
			.cp-list:nth-child(1){ width: 50%; text-align: left;}
			.cp-list:nth-child(2){ width: 23%;}
			.cp-list:nth-child(3){ width: 10%;}
			.cp-list:nth-child(4){ width: 10%;}
			.cp-list:nth-child(5){ width: 7%;}
			.cp-list.cp-list-step3-last{ width: 17%;}
			.cpl-img{
				width: 50px;
				height: 50px;
				background-size: cover;
				background-position: center;
				margin-right: 15px;
				display: inline-block;
				vertical-align: middle;
			}
			.cpl-title{
				color: #333333;
				display: inline-block;
				width: 360px;
				line-height: 20px;
				letter-spacing: 1px;
				height: 50px;
				vertical-align: middle;
			}
			.cpl-single-price, .cpl-total-price{
				color: #98580C;
				font-size: 16px;
				line-height: 50px;
			}
			.cpl-number input{
				height: 30px;
				width: 40px;
				margin: 0;
				color: #555;
				text-align: center;
			}
			.cpl-number{
				display: inline-block;
				margin: 10px 4px;
				height: 30px;
				vertical-align: middle;
			}
			.cpl-number-text{
				line-height: 50px;
				vertical-align: middle;
			}
			.cpl-subr, .cpl-add{
				width: 36px;
				height: 30px;
				line-height: 30px;
				display: inline-block;
				border: 1px solid #e4e4e4;
				font-size: 12px;
				color: #aaa;
				margin: 10px 0;
				vertical-align: middle;
				background: #fff;
				cursor: pointer;
				text-align: center;
				&:hover{
					background: #e8e8e8;
				}
			}
			.cpl-delete{
				cursor: pointer;
				text-align: center;
				line-height: 50px;
				i{
					font-size: 22px;
					line-height: 50px;
				}
				&:hover{
					i{
						color: #AE1D00;
					}
				}
			}
		}
	}
	.cart-tfoot{
		text-align: right;
		h4{
			font-size: 16px;
			color: #DC6064;
			margin: 0 0 10px 0;
			.ct-dollortype{
				font-size: 13px;
				margin-right: 3px;
			}
			.ct-totalmoney{
				font-size: 20px;
			}
		}
		p{
			font-size: 14px;
		}
	}
}
.checkout-buttons{
	margin: 40px 0;
}
.checkout-backtomall{
	margin: 60px 0 20px 0;
	text-decoration: underline;
	font-size: 14px;
	a{
		color: #539D63;
		&:hover{
			color: #888;
		}
	}
}
.cart-info{
	margin: 10px 0 30px 0;
	strong{
		display: inline-block;
		width: 90px;
	}
	p{
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 3px;
	}
}

.system-message{
	padding: 30px 0;
	h3{
		font-weight:normal;
		font-family: Arial, 'xingothic-tc';
		letter-spacing: 1px;
		color: #333;
		font-size: 24px;
	}
	p{
		font-size: 15px;
		line-height: 28px;
		color: #333;
	}
	p.icon{
		i{
			font-size: 160px;
			color: #666;
			margin: 25px 0 5px 0;
		}
	}
}

.row.zipcode{
	margin: 0 -5px;
	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12{
		padding: 0px 5px;
	}
}

#globalShop{
	.modal-dialog{
		width: 800px;
		margin: 30px auto;
	}
	.global-info{
		margin-bottom: 20px;
	}
	.form-group{
		margin-bottom: 20px;
		textarea{
			height: 150px;
		}
	}
}

.modal{
	.modal-header{
		background: #F3F9F2;
		text-align: center;
	}

}

.orderlist_table{
	thead td{
		color: #bbb;
		font-weight: bold;
	}
	a{
		color: #19AC57;
		&:hover, &:active{
			color: darken(#19AC57,10%);
		}
	}
	border-bottom: 1px solid #ddd;
	.olt_buttons{
		width: 240px;
		form{
			display: inline-block;
		}
		.btn{
			padding: 1px 5px;
			font-size: 12px;
			line-height: 1.5;
			border-radius: 10px;
		}
	}
}

.faqvision{
	margin-bottom: 20px;
}
.faqnav{
	margin-bottom: 20px;
}
.faqs{
	.panel{
		box-shadow: none;
		border-bottom: 1px dotted #ddd;
		//margin-bottom: 20px;
	}
	.panel-head{
		padding: 10px 0;
		h4{
			font-size: 18px;
			letter-spacing: 1px;
			color: #7c550b;
			span.no{
				border: 1px solid #bf8c00;
				color: #bf8c00;
				width: 36px;
				height: 36px;
				border-radius: 36px;
				text-align: center;
				line-height: 36px;
				display: inline-block;
				margin-right: 10px;
				font-family: "Times New Roman";
				font-style: italic;
			}
		}
		&:hover{
			h4{
				color: #333;
			}
		}
	}
	.answer{
		.panel-body{
			padding: 7px 0px;
			p{
				font-size: 16px;
				line-height:32px;
				color: #555;
				padding-left: 46px;
			}
		}
	}
}
.faq-category{
	margin: 40px 0;
	ul{
		li{
			a{
				color: #775c03;
				border: 1px solid #b98f51;
				//border-bottom: none;

				&.active, &:hover.active{
					color: #fff;
					border: 1px solid #e7b265;
					background: #e7b265;
					&:before{
						content: '';
						width: 0;
						height: 0;
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-top: 6px solid #e7b265;
						position: absolute;
						bottom: -6px;
						left: 50%;
						margin-left: -3px;
					}
				}
				&:hover{
					background: none;
				}
			}
		}
	}
}

.topvision{
	height: 350px;
	margin-bottom: 50px;
	margin-top: -30px;
	overflow: hidden;
	img{
		width: 100%;
	}
}

.product-sidebar{
	box-shadow: 7px 0 16px -8px rgba(0, 0, 0, .2);
	padding: 30px 0;
	margin-right: 30px;
	margin-top: 0px;
}
.product-sidebar-list{
	margin: 0;
	padding: 0;
	li{
		list-style: none;
		padding-bottom: 16px;
		margin-bottom: 16px;
		border-bottom: 1px dotted #ccc;
		width: 82%;
		position: relative;
		a{
			font-size: 15px;
			color: #0E411A;
		}
		&.active {
			a{
				color: #0D8229;
			}
			&:after{
				content: "";
				width: 40px;
				height: 2px;
				position: absolute;
				bottom: 0px;
				left: 0px;
				background: #0D8229;
			}
		}
	}
}

.plyr--video{
	border: 1px solid #afa593;
}

.witness-item{
	margin-bottom: 70px;
	padding: 0 5px;
	.witness-img{
		border-radius: 250px;
	}
	.witness-title{
		font-weight: bold;
		color: #006a2f;
		border-bottom: 1px dotted #006a2f;
		font-size: 20px;
	}
	.witness-sub{
		font-size: 13px;
		color: #888;
		margin-bottom: 10px;
	}
	.witness-content{
		margin-top: 10px;
	}
	&.witness-item-2{
		border: 1px solid #ddd;
		background: #fefefe;
		padding:0 20px;
		.witness-title{
			font-size: 16px;
		}
	}
	&.shadow{
		box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
	}
	&.last{
		margin-bottom: 300px;
	}
}

.checkout-result{
	background: #fff;
	padding: 30px;
	h3{
		font-size: 28px;
		text-align: center;
		letter-spacing: 1px;
		margin-bottom: 20px;
		margin-top: 0px;
		&.hsuccess{
			color: #349e42;
		}
		&.hdanger{
			color: #9e2830;
		}
	}
	p{
		font-size: 14px;
		text-align: center;
	}
	.buttons{
		margin-top: 50px;
	}
	p.icon{
		i{
			font-size: 160px;
			color: #666;
			margin: 25px 0 5px 0;
		}
	}
}

.order-lists{
	border: 1px solid #ddd;
	padding: 0px 15px;
	background: #fcfcfc;
	border-left: 3px solid #9c721a;
}
.order-list-item{
	margin-bottom: 0px;
	border-bottom: 1px solid #ddd;
	padding: 18px 0;
	&:last-child{
		border-bottom: none;
		margin-bottom: 0px;
	}
	h4{
		font-size: 16px;
		color: #9c721a;
		font-weight: normal;
		margin: 0 0 10px 0;
	}
}

.space{
	display: inline-block;
	width: 18px;
	opacity: 0;
}

.orderprint{
	font-size: 12px;
	table.table{
		>tbody, >thead{
			>tr{
				>td, >th{
					font-size: 13px;
					padding: 3px 5px;
					vertical-align: middle;
					border: 1px solid #aaa;
				}
				>th{
					background-color: #eee;
				}
			}
		}
	}
	p{
		margin-bottom: 0px;
	}
}