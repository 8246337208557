/* Import Bootstrap */
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/* Import Font-Awesome */
@import "node_modules/font-awesome/scss/font-awesome";

/* Import SASS-MQ */
@import "node_modules/sass-mq/mq";

/* Import Font-Awesome */
@import "node_modules/bxslider/dist/jquery.bxslider";

/* Import Google Font*/
@import 'https://fonts.googleapis.com/css?family=Oxygen';

/* Import AOS*/
@import 'node_modules/aos/dist/aos';

/* Import Plyr*/
@import 'plyr-custom';

/* 基本樣式 */
@import "main.scss";
@import "rwd.scss";

/* 頁面樣式 */