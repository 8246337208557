@include mq($until: tablet) {

  body {
    padding-bottom: 0px;
  }
  header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 70px;
    background: #fff;
    z-index: 99999;
    box-shadow: 0px 2px 8px rgba(36, 82, 47, 0.6);
  }
  .index-product-intro .hyc-intro-img {
    margin-top: 0px;
  }
  .mobile-nav {
    .logo {
      width: 100px;
      margin: 5px auto;
    }
    .mobile-menu-toggle, .mobile-user-menu-toggle {
      margin-top: 10px;
      padding: 15px 0px;
      text-align: center;
      i {
        font-size: 18px;
        color: #65a542;
      }
    }
    .mobile-menu-toggle button {
      border: none;
      background: none;
    }
    .mobile-user-menu-toggle{
      position: relative;
      >span{
        position: absolute;
        bottom: 6px;
        right: -6px;
        background: #9c721a;
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        padding: 1px 0;
        border: 1px solid #fff;
      }
    }
  }
  .index-wrap {
    margin-top: -30px;
  }
  .main-content {
    height: 100%;
    overflow-y: hidden;
    padding-top: 100px;
  }
  .login-form .login-button {
    .btn {
      width: 100%;
      margin: 5px 0;
    }
  }
  .main-title span.big {
    padding-left: 0px;
    padding-right: 0px;
    width: 65%;
  }
  .index-slider li {
    height: 220px;
  }
  .index-product-intro > .container {
    padding: 0px 15px;
  }
  .ipi-fullwidth {
    margin: 0 -15px;
  }
  .index-product-intro .ipi-content h3 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 24px;
    padding-bottom: 20px;
    line-height: 24px;
  }
  .index-product-intro {
    margin-bottom: 20px;
  }
  .index-shop .index-products {
    margin-top: 20px;
  }
  .index-shop .index-product-item {
    padding: 0px 15px;
    margin-bottom: 35px;
  }
  .index-shop .index-product-item .ipi-img {
    height: 240px;
  }
  .index-shop .index-product-item .ipi-title {
    margin: 10px 0;
  }
  .index-shop .index-product-item .ipi-info {
    margin-bottom: 10px;
  }
  .index-new .index-new-item {
    margin-bottom: 40px;
  }
  .index-about .index-about-img {
    width: 100%;
    float: none;
    height: 250px;
  }
  .index-about .index-about-text {
    width: 100%;
    float: none;
    height: auto;
    padding: 20px;
  }
  .index-about .index-about-text .ibt-title {
    font-size: 20px;
    line-height: 26px;
  }
  .index-about .index-about-text .ibt-text {
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 28px;
  }
  footer .footer-links-bg {
    margin-top: 0px;
  }
  .index-product-intro {
    padding-bottom: 30px;
  }
  .index-product-intro .ipi-content p {
    padding: 0 30px;
  }
  .index-new, .index-shop {
    padding: 20px 0;
    border-top: 1px solid #ddd;
  }
  .footer-links-div {
    margin-bottom: 25px;
  }
  footer .footer-content-bg {
    height: auto;
    position: relative;
  }
  .mobile-menu {
    width: 100%;
    position: fixed;
    top: 70px;
    left: 0px;
    background: rgba(255, 255, 255, 0.9);
    z-index: 10000;
    box-shadow: 0px 2px 3px #aaa;
    a {
      color: #000;
    }
    .search-input{
      padding: 10px 15px;
      width: 100%;
      display: block;
      input{
        width: 100%;
        height: 34px;
        border: 1px solid #ddd;
        padding: 6px 12px;
        border-radius: 5px;
        &:focus{
          outline: none;
        }
      }
      i{
        position: absolute;
        right: 30px;
        top: 18px;
      }
    }
    .navbar-nav > li > a {
      padding: 5px 30px;
      letter-spacing: 1px;
      text-align: left;
    }
    .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
      background: rgba(255, 255, 255, 0.9);
    }
  }
  .topvision {
    height: 200px;
    margin-bottom: 25px;
  }
  .page-title span {
    display: block;
    top: inherit;
    margin-left: 0px;
  }
  .sidebar-dropdown {
    margin-bottom: 30px;
    > .dropdown-menu {
      width: 100%;
      > .active > a {
        background: #0D8229;
      }
    }
  }
  .article-page h3 {
    margin-bottom: 30px;
  }
  .product-detail {
    margin-top: 20px;
  }
  .pd-imgs {
    margin-bottom: 30px;
  }
  .product-detail .pd-intro .pd-title {
    margin-bottom: 15px;
    padding-bottom: 12px;
  }
  .product-list .product-item .pi-img {
    height: 350px;
  }
  .faqs .panel-head h4 span.no {
    text-indent: 0px;
  }
  .faqs .panel-head h4 {
    text-indent: -46px;
    padding-left: 46px;
  }
  .index-product-intro .ipi-img-bg {
    height: 190px;
    background-size: cover;
  }
  .checkout-step .step {
    width: 50%;
    height: 70px;
  }
  .checkout-step {
    margin: 15px 0;
  }
  .cart-table {
    .cart-thead {
      display: none;
    }
    .cart-tbody {
      .cart-product {
        height: auto;
        vertical-align: middle;
        border-bottom: 1px dotted #ddd;
        .cp-list {
          display: block;
          text-align: left;
          float: none;
        }
        .cpl-single-price, .cpl-total-price, .cpl-number-text {
          line-height: 34px;
          font-size: 14px;
        }
        .cp-list:nth-child(1) {
          width: 100%;
          margin-bottom: 15px;
        }
        .cp-list:nth-child(2) {
          width: 100%;
        }
        .cp-list:nth-child(3) {
          width: 100%;
        }
        .cp-list:nth-child(4) {
          width: 100%;
        }
        .cp-list:nth-child(5) {
          width: 100%;
          margin: 15px 0;
        }
        .cp-list.cp-list-step3-last {
          width: 100%;
        }
      }
    }
    .cart-tfoot {
      text-align: right;
      h4 {
        font-size: 16px;
        color: #DC6064;
        margin: 0 0 10px 0;
        .ct-dollortype {
          font-size: 13px;
          margin-right: 3px;
        }
        .ct-totalmoney {
          font-size: 20px;
        }
      }
      p {
        font-size: 14px;
      }
    }
  }
  .checkout-cart {
    padding: 10px 20px;
  }
  .cart-table .cart-tbody .cart-product .cpl-title {
    width: 270px;
  }
  .cpl-mobile-title {
    color: #333;
    margin-right: 4px;
    display: inline-block !important;
  }
  .cart-table .cart-tbody .cart-product .cpl-title {
    height: auto;
  }
  .checkout-buttons .btn {
    margin-bottom: 10px;
  }
  .witness-item {
    &.last {
      margin-bottom: 100px;
    }
  }
  .checkout-result{
    padding: 30px 10px;
  }
  .navbar-nav .open .dropdown-menu{
    background: #f8f8f8;
    padding: 0px;
    >li{
      border-bottom: 1px solid #eee;
      &:first-child{
        border-top: 1px solid #eee;
      }
    }
  }

}


@include mq($until: small){
  .cart-table .cart-tbody .cart-product .cpl-title{
    width: 175px;
  }
}

@include mq($until: mobile-s){
  .cart-table .cart-tbody .cart-product .cpl-title{
    width: 230px;
  }
}

@include mq(mobile,desktop) {
  .index-slider li{
    height: 400px;
  }
  .mainnav-wrap .logo{
    width: 90px;
    margin-top: 24px;
  }
  .index-product-intro .ipi-content p{
    padding: 0 10px;
  }
  .index-product-intro .ipi-content h3{
    margin-bottom: 30px;
    padding-bottom: 10px;
  }
  .index-shop .index-product-item{
    margin-bottom: 40px;
  }
  .index-new .index-new-item .ini-img{
    height: 155px;
  }
  .index-about .index-about-text{
    padding: 50px 20px;
  }
  .index-about .index-about-text .ibt-title{
    font-size: 20px;
    line-height: 32px;
  }
  .mainnav-wrap .main-nav > li .title.cht-jp{
    font-size: 14px;
  }
  .mainnav-wrap .main-nav > li:after{
    top: 33px;
  }
  .mainnav-wrap .main-nav > li{
    width: 85px;
  }
}

@include mq(desktop, wide) {
  .product-list .product-item{
    height: 401px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .index-slider li{
    height: 500px;
  }
  .index-about .index-about-text{
    padding: 90px 60px !important;
  }
}